/*!*
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Roadhouse Bold No3 by Kimmy Design
 * URL: https://www.myfonts.com/products/bold-no3-roadhouse-246771
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * © 2024 MyFonts Inc. */

@tailwind base;
@tailwind components;
@tailwind utilities;
